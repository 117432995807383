import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

const Privacy = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="template" lang="ja" />
        <title>{`個人情報の取扱について | ${title}`}</title>
        <meta
          name="description"
          content="個人情報の取扱についてのご案内です。"
        />
      </Helmet>
      <div className="common-header clearfix">
        <h1>個人情報の取扱について</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>個人情報の取扱について</li>
        </div>
      </div>

      <div className="main-wrapper">
        <div className="row">
          <div className="container">
            株式会社アイテック（以下、当社といいます）は、当社が業務を遂行するために取得及び利用する個人情報の取扱について次の通り、公表します。
            <br />
            <br />
            <h3>１．利用目的</h3>
            当社は、個人情報を下記の利用目的の達成に必要な範囲で利用いたします。
            <br />
            （１）当社の製品またはサービスに関するお問い合せに対して回答するため
            <br />
            （２）取引先との連絡、協力、交渉、契約の履行、請求等のため
            <br />
            （３）QT-net会員様向けサービス提供のため
            <br />
            （４）自営駐車場管理のため
            <br />
            （５）従業員、採用応募者、退職者等の雇用及び人事管理のため
            <br />
            （６）受託運営管理業務（コールセンター業務含む）の遂行のため
            <br />
            &emsp;&emsp;&emsp;コールセンターではサービス向上の目的で通話を録音しています
            <br />
            （７）通信回線契約、加盟店契約等の代行業務遂行のため
            <br />
            上記個人情報のうち、開示対象個人情報は（１）～（５）です。
            <br />
            <br />
            <br />
            【クレジットカード情報の取扱について】
            <br />
            当社がクレジットカード決済において、お客様から取得するクレジットカード情報の利用目的、取得者名、提供先、保存期間は以下の通りです。
            <br />
            ・利用目的
            <br />
            &emsp;ご提供するサービスの代金を決済するため
            <br />
            ・情報の取得者名
            <br />
            &emsp;株式会社アイテック
            <br />
            ・情報の提供先
            <br />
            &emsp;お客様が決済時にご利用いただいたクレジットカードの発行会社
            <br />
            ・保存期間
            <br />
            &emsp;サービス代金決済時より7年間
            <br />
            <br />
            <br />
            <h3>２．個人情報を入力するにあたっての注意事項</h3>
            （１）当社がご提供するサービスのほとんどは、お客さまの個人情報を必要とせずご利用いただけます。
            但し、お客さまが個人情報を当社にご提供いただけない場合にはご提供できないサービスもありますので、ご了承ください。
            <br />
            （２）個人情報を当社に与えることについてご本人様の任意性は存在しますが、与えられなかった場合は、当該利用目的を達成することができず、ご本人様が期待された結果が得られないことがあります。
            <br />
            <br />
            <br />
            <h3>３．業務委託</h3>
            上記１の利用目的を達成する範囲で一部または全部の業務を外部事業者に委託を行うことがありますが、当社の選定基準を満たす委託先を選定するとともに、個人情報の取扱に関する契約を締結し、適切な監督をいたします。
            <br />
            <br />
            <br />
            <h3>４．個人情報の提供</h3>
            当社は、次の場合を除き、当社以外の第三者に、個人情報のご本人様の事前の同意なく提供することはございません。
            <br />
            （１）法令に基づく場合
            <br />
            （２）人の生命、身体又は財産の保護の為に必要であってお客様の同意を取ることは困難であるとき
            <br />
            （３）公衆衛生の向上または児童の健全な育成の推進の為に特に必要である場合であって、本人の同意を得ることが困難であるとき
            <br />
            （４）国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力をする必要がある場合であって、本人の同意を得ることによって当該事務の遂行に支障を及ぼす恐れのあるとき
            <br />
            <br />
            <br />
            <h3>５. 匿名加工情報に関する公表について</h3>
            当社は、個人情報を特定の個人を識別できないように加工した情報（以下、「匿名加工情報」と言います。）の作成・受領を行いません。
            <br />
            <br />
            <br />
            <h3>６. 仮名加工情報に関する公表について</h3>
            当社は、個人情報を特定の個人を容易に識別できないように加工した情報（以下、「仮名加工情報」と言います。）の作成・受領を行いません。
            <br />
            <br />
            <br />
            <h3>７. 個人情報保護管理者について</h3>
            次の者を当社の個人情報保護管理者とします。
            <br />
            <br />
            【所属】
            <br />
            株式会社アイテック
            <br />
            【個人情報保護管理者】
            <br />
            運営管理部 部長
            <br />
            【住所】
            <br />
            〒112-0004 東京都文京区後楽2丁目1番3号　ＮＳ飯田橋ビル1階
            <br />
            【連絡先】
            <br />
            TEL： 03-5802-6871　FAX： 03-5802-6872
            <br />
            E-Mail：pms@i-tech-corp.co.jp
            <br />
            【受付時間】
            <br />
            10:00～17:00 但し、土・日・祝日・年末年始は休業となります。
            <br />
            <br />
            <br />
            <h3>８. 苦情・ご相談、開示等のご請求手続き</h3>
            （１）当社は当社が保有する個人情報について、苦情・ご相談、個人情報のご本人様又はその代理人様から当該個人情報の開示、利用目的の通知、訂正・削除、利用又は第三者提供の停止のご請求（以下、「開示等のご請求」といいます。）がある場合は、次の通り対応いたします。
            <br />
            （２）個人情報に関する開示等のご請求窓口
            <br />
            &emsp;&emsp;&emsp;次の者を当社の個人情報保護管理者の代理人とし、開示等のご請求に対応します。
            <br />
            <br />
            &emsp;&emsp;&emsp;■個人情報保護管理者及び個人情報に関する開示等のご請求窓口
            <br />
            &emsp;&emsp;&emsp;【所属】
            <br />
            &emsp;&emsp;&emsp;株式会社アイテック
            <br />
            &emsp;&emsp;&emsp;【開示等の問い合わせ窓口】
            <br />
            &emsp;&emsp;&emsp;個人情報保護相談窓口担当者
            <br />
            &emsp;&emsp;&emsp;【住所】
            <br />
            &emsp;&emsp;&emsp;〒112-0004
            東京都文京区後楽2丁目1番3号　ＮＳ飯田橋ビル1階
            <br />
            &emsp;&emsp;&emsp;【連絡先】
            <br />
            &emsp;&emsp;&emsp;TEL： 03-5802-6871　FAX： 03-5802-6872
            <br />
            &emsp;&emsp;&emsp;E-Mail：pms@i-tech-corp.co.jp
            <br />
            &emsp;&emsp;&emsp;【受付時間】
            <br />
            &emsp;&emsp;&emsp;10:00～17:00
            但し、土・日・祝日・年末年始は休業となります。
            <br />
            <br />
            （３）開示等のご請求の方法
            <br />
            &emsp;&emsp;&emsp;開示等のご請求については、当社所定の『個人情報開示・訂正・削除依頼書』を印刷の上、必要事項を自筆にてご記入戴き、必要書類（注１）を添付の上、上記「個人情報保護管理者及び個人情報に関する開示等のご請求窓口」宛に、配達記録が確認できる手段（特定記録郵便、簡易書留郵便）にてご郵送ください。
            <br />
            <br />
            &emsp;&emsp;&emsp;※ 注１：請求書に添付する必要書類
            <br />
            &emsp;&emsp;&emsp;開示等のご請求を戴いた方が、ご本人様であることを確認するために次のいずれかの書類のコピーを請求書に添付してください。
            <br />
            &emsp;&emsp;&emsp;１）運転免許証 １通
            <br />
            &emsp;&emsp;&emsp;２）パスポート １通
            <br />
            &emsp;&emsp;&emsp;３）在留カード １通
            <br />
            &emsp;&emsp;&emsp;４）健康保険証＋３ヶ月以内に取得した住民票 各１通
            <br />
            <br />
            &emsp;&emsp;&emsp;代理人様（注２）からご請求される場合は、代理人様ご自身の上記書類に加えて次の書類の添付をお願いします。
            <br />
            <br />
            &emsp;&emsp;&emsp;１）法定代理人が親権者の場合
            <br />
            &emsp;&emsp;&emsp;&emsp;‧
            戸籍謄本、抄本の写しなど本人と関係を証する書類１通
            <br />
            &emsp;&emsp;&emsp;２）法定代理人が成年後見人の場合
            <br />
            &emsp;&emsp;&emsp;&emsp;‧ 登記事項証明書（写し）１通
            <br />
            &emsp;&emsp;&emsp;３）法定代理人が未成年後見人の場合
            <br />
            &emsp;&emsp;&emsp;&emsp;‧ 未成年後見登記事項証明証（写し）１通
            <br />
            &emsp;&emsp;&emsp;４）任意代理人の場合
            <br />
            &emsp;&emsp;&emsp;&emsp;‧ ご本人様が自署、押印した委任状（原本）１通
            <br />
            <br />
            &emsp;&emsp;&emsp;※
            注２：請求可能な代理人様は次にいずれか該当する場合となります。
            <br />
            &emsp;&emsp;&emsp;&emsp;‧ 法定代理人
            <br />
            &emsp;&emsp;&emsp;&emsp;‧ 親権者（民法 818 条に規定）
            <br />
            &emsp;&emsp;&emsp;&emsp;‧ 成年後見人（民法 8 条・843 条に規定）
            <br />
            &emsp;&emsp;&emsp;&emsp;‧ 未成年後見人（民法 839 条・840 条に規定）
            <br />
            &emsp;&emsp;&emsp;&emsp;‧ 任意代理人（ただし、本人が 15
            歳以上であること）
            <br />
            <br />
            （４）利用目的の通知および開示のご請求における費用
            <br />
            &emsp;&emsp;&emsp;利用目的の通知および開示のご請求 1
            回あたり、手数料 1,000 円（税込み）をいただきます。1,000
            円分の郵便切手を提出書類と一緒にご同封ください。当社への郵送料はご本人様にてご負担ください。
            <br />
            &emsp;&emsp;&emsp;※
            手数料が不足していた場合、及び上記手数料が同封されていなかった場合は、その旨ご連絡いたしますが、所定の期間内にお支払いいただけない場合は、利用目的の通知および開示のご請求がなかったものとして対応させていただきますので、ご了承願います。
            <br />
            （５）開示等のご請求に対する回答方法
            <br />
            &emsp;&emsp;&emsp;請求書を受領後 1
            ヶ月以内に、添付書類記載の住所宛に、本人限定受取書留郵便にてご回答いたします。
            <br />
            <br />
            <br />
            2009 年 7 月 1 日制定
            <br />
            2009 年 11 月 5 日改定
            <br />
            2011 年 12 月 1 日改定
            <br />
            2013 年 11 月 14 日改定
            <br />
            2015 年 11 月 26 日改定
            <br />
            2017 年 12 月 1 日改定
            <br />
            2021 年 6 月 1 日改訂
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Privacy
